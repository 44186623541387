import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SuccessModal from "containers/BlogPage/BlogModal/SuccessModal";
import FailModal from "containers/BlogPage/BlogModal/FailModal";

// Types & Interfaces
interface ApiError {
  message: string;
  errors?: {
    [key: string]: string[];
  };
}

interface FormData {
  name: string;
  email: string;
  password: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  password?: string;
}

export interface PageSignUpProps {
  className?: string;
}

// Component Definition
const PageSignUp: React.FC<PageSignUpProps> = ({ className = "" }) => {
  // Navigation
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Utility Functions
  const getErrorMessage = (error: ApiError): string => {
    if (error.errors) {
      const errorMessages = Object.entries(error.errors).map(([field, messages]) => {
        switch(field) {
          case 'email':
            if (messages.includes('validation.unique')) {
              return 'This email is already registered';
            }
            return 'Please enter a valid email address';
          case 'password':
            if (messages.includes('validation.min.string')) {
              return 'Password must be at least 6 characters long';
            }
            return 'Invalid password format';
          case 'name':
            return 'Name is required';
          default:
            return `${field}: ${messages[0]}`;
        }
      });
      return errorMessages.join('\n');
    }
    return error.message || 'An unexpected error occurred during registration';
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  // Event Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate('/');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://develop.coursemos.co.kr/api/v1/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw data;
      }

      setFormData({
        name: "",
        email: "",
        password: "",
      });
      setShowSuccessModal(true);
    } catch (err) {
      const apiError = err as ApiError;
      setErrorMessage(getErrorMessage(apiError));
      setShowFailModal(true);

      if (apiError.errors?.email?.includes('validation.unique')) {
        setFormErrors(prev => ({
          ...prev,
          email: 'This email is already registered',
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
        <Helmet>
          <title>Coursemos Tech Feed</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Signup
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
              <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name
              </span>
                <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`mt-1 ${formErrors.name ? 'border-red-500' : ''}`}
                    required
                />
                {formErrors.name && (
                    <span className="text-red-500 text-sm mt-1">{formErrors.name}</span>
                )}
              </label>
              <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
                <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="example@example.com"
                    className={`mt-1 ${formErrors.email ? 'border-red-500' : ''}`}
                    required
                />
                {formErrors.email && (
                    <span className="text-red-500 text-sm mt-1">{formErrors.email}</span>
                )}
              </label>
              <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
                <Input
                    type="password"
                    name="password"
                    placeholder="6+ characters required"
                    value={formData.password}
                    onChange={handleInputChange}
                    className={`mt-1 ${formErrors.password ? 'border-red-500' : ''}`}
                    required
                />
                {formErrors.password && (
                    <span className="text-red-500 text-sm mt-1">{formErrors.password}</span>
                )}
              </label>
              <ButtonPrimary
                  type="submit"
                  disabled={isLoading}
              >
                {isLoading ? "Signing up..." : "Continue"}
              </ButtonPrimary>
            </form>

            <span className="block text-center text-neutral-700 dark:text-neutral-200">
              Already have an account? {` `}
              <Link to="/login" className="text-blue-600 font-semibold hover:underline">
                Sign in
              </Link>
          </span>
          </div>
        </div>

        <SuccessModal
            isOpen={showSuccessModal}
            onClose={handleSuccessModalClose}
            title="Registration Successful"
            mainMessage="Waiting for approval. Stay ready!"
            subMessage="This usually takes 1-2 business days"
        />

        <FailModal
            isOpen={showFailModal}
            onClose={() => setShowFailModal(false)}
            title="Registration Failed"
            mainMessage="Unable to complete registration"
            subMessage={errorMessage}
        />
      </div>
  );
};

export default PageSignUp;